<template>
  <div class="h-full w-full bg-[#F0F2F6]">
    <!-- loading遮罩 -->
    <van-overlay :show="pageLoading" class="flex items-center justify-center z-50">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </van-overlay>

    <!-- 顶部区域 -->
    <div class="relative">
      <img src="@/assets/img/bg_page_top.png" class="absolute top-0 left-0 w-full h-[220px] object-cover flex-shrink-0" />
      <div class="px-4 pt-4 pb-20 relative z-10">
        <div class="flex items-center" @click="onClickLeft">
          <van-icon name="arrow-left" class="text-white text-xl mr-2" />
          <div class="text-white text-xl">邀请好友</div>
        </div>
        <div class="mt-4 text-white text-base opacity-80">
          注册实名就送<span class="text-[#F0FF20]">20000</span>元现金<span class="text-[#F0FF20]">50000</span>资产清算额度
          <br>
          每邀请1人实名注册送<span class="text-[#F0FF20]">5000</span>元现金和<span class="text-[#F0FF20]">10000</span>元资产清算额度
        </div>
      </div>
    </div>

    <!-- 内容区域 -->
    <div class="px-4 -mt-16 relative z-20">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div class="bg-white rounded-lg p-4 shadow-sm">
          <!-- 邀请奖励标题 -->
          <div class="flex items-center mb-4">
            <div class="flex flex-col items-center">
              <span class="text-[#F23D30] font-medium text-xl">邀请奖励</span>
              <div class="w-[20px] h-[3px] bg-[#F23D30] mt-1 rounded-[8px]"></div>
            </div>
          </div>

          <!-- 二维码区域 -->
          <div class="relative mb-4 rounded-lg overflow-hidden">
            <img src="@/assets/img/invite/bg_invite.png" class="w-full h-full absolute top-0 left-0 object-cover" />
            <div class="relative z-10 p-4">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <div class="w-[90px] flex justify-center">
                    <div id="qrcode"></div>
                  </div>

                  <div class="ml-3">
                    <div class="text-base text-[#333333]">邀请码</div>
                    <div class="mt-2 text-xl font-medium text-[#333333]">{{ code }}</div>
                  </div>
                </div>

                <div class="flex items-center">
                  <button @click="onCopyText(url)"
                          class="flex items-center text-[#F23D30] text-sm">
                    <img src="@/assets/img/invite/icon_copy.png" class="w-5 h-5 mr-1" />
                    复制推广链接
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- 奖励列表 -->
          <div class="space-y-3">
            <div v-for="(item, index) in rewardList" :key="index"
                 class="bg-[#FFF7F7] rounded-[10px] p-3 relative">
              <div class="flex">
                <img src="@/assets/img/invite/list_icon.png" class="w-[81px] h-[71px] rounded-[6px] flex-shrink-0"/>
                <div class="flex-1 ml-3">
                  <div class="text-[#333333] ">{{ item.describe }}</div>
                  <div class="flex space-x-6 mt-2 mb-8">
                    <div class="flex flex-col">
                      <div class="flex items-baseline">
                        <span class="text-[#FF1D1D] font-[Alata] text-lg">{{ item.envAmount }}</span>
                        <span class="text-[#FF1D1D] ml-1 text-sm">元</span>
                      </div>
                      <div class="text-[#999999] text-xs mt-[2px]">现金</div>
                    </div>
                    <div class="flex flex-col">
                      <div class="flex items-baseline">
                        <span class="text-[#FF1D1D] font-[Alata] text-lg">{{ item.amount }}</span>
                        <span class="text-[#FF1D1D] ml-1 text-sm">元</span>
                      </div>
                      <div class="text-[#999999] text-xs mt-[2px]">资产清算额度</div>
                    </div>
                  </div>
                </div>
                <button @click="handleButtonClick(item)"
                        class="absolute right-3 bottom-3 w-[77px] h-[23px] rounded-[4px] text-white text-sm"
                        :class="getButtonClass(item.status)">
                  {{ getButtonText(item.status) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import {Icon,  Picker, Popup, PullRefresh, Overlay, Loading} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {mapState} from "vuex";
import QRCode from "qrcodejs2";

export default {
  name: "InviteUser",
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    code() {
      return this.userInfo?.code || '';
    },
    url() {
      if (!this.config) return '';
      return `${this.config.WEB_URL}/register?code=${this.code}`;
    }
  },
  data() {
    return {
      showProvincePicker: false,
      showCityPicker: false,
      selectedProvince: '',
      selectedCity: '',
      provinces: [],
      cities: {},
      refreshing: false,
      loadingIcon: 'loading',
      rewardList: [],
      loading: false,
      pageLoading: true,
      config: null,
    };
  },

  async created() {
    await this.loadConfig();
    await this.getActivatedList();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },

    jumpToPage(name) {
      this.$router.push({name});
    },

    onRefresh() {
      this.refreshData()
    },
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
   async loadMore() {
        // 重新获取数据
        await this.getActivatedList()
        this.refreshing = false
        this.pageLoading = false
    },

    // 获取激活列表数据
    async getActivatedList() {
      if (this.loading) return
      this.loading = true

      try {
        const res = await  axios.get(api.receiveList, { params: { type: 2}})

        if (res.data?.inviteConfigList) {
          this.rewardList = res.data.inviteConfigList.map(item => ({
            icon: require('@/assets/img/invite/user_invite.png'),
            describe: item.describe,
            amount: item.gift_coin_num,
            envAmount: item.invite_award_num,
            status: item.status,
            id: item.id
          }))
        } else {
          this.$toast(res.msg || '获取数据失败')
        }
      } catch (e) {
        console.log('获取邀请列表失败:', e)
        this.$toast('网络错误,请稍后重试')
      } finally {
        this.loading = false
        this.pageLoading = false
      }
    },

    getButtonText(status) {
      switch(status) {
        case 1: return '去邀请'
        case 2: return '去领取'
        case 3: return '已领取'
        default: return '去邀请'
      }
    },

    getButtonClass(status) {
      switch(status) {
        case 1: // 去邀请 - 红色渐变
          return 'bg-gradient-to-r from-[#F23D30] via-[#FF6A57] to-[#F23D30]'
        case 2: // 去领取 - 绿色
          return 'bg-[#4CAF50]'
        case 3: // 已领取 - 灰色
          return 'bg-[#999999]'
        default:
          return 'bg-gradient-to-r from-[#F23D30] via-[#FF6A57] to-[#F23D30]'
      }
    },

    async handleButtonClick(item) {
      switch(item.status) {
        case 1: // 去邀请
        case 3: //  已领取
          this.$router.push({name: 'inviteUser', query: {code: this.userInfo.code}});
          break
        case 2: // 去领取
          try {
            const formData = new FormData();
            formData.append('id', item.id);
            const res = await axios.post(api.receiveAward, formData)
            if(res.code === 0) {
              this.$toast('领取成功')
              await this.getActivatedList() // 刷新列表
            } else {
              this.$toast(res.msg || '领取失败')
            }
          } catch(e) {
            console.error('领取奖励失败:', e)
            this.$toast(e.msg||'网络错误,请稍后重试')
          }
          break
      }
    },

    async loadConfig() {
      try {
        const configRes = await fetch('/config.prod.json');
        this.config = await configRes.json();
        if (this.code) {
          await this.$nextTick();
          await this.generateQRCode();
        }
      } catch (error) {
        console.log('加载配置失败:', error);
        this.$toast('加载配置失败');
      }
    },

    async generateQRCode() {
      try {
        if (!this.config || !this.code) {
          return;
        }
        const qrcodeContainer = document.getElementById("qrcode");
        if (!qrcodeContainer) {
          return;
        }
        qrcodeContainer.innerHTML = '';

        new QRCode(qrcodeContainer, {
          text: this.url,
          width: 90,
          height: 90,
          colorDark : "#000000",
          colorLight : "#ffffff",
          correctLevel : QRCode.CorrectLevel.H
        });
      } catch (err) {
        console.log('生成二维码失败:', err);
        this.$toast('生成二维码失败');
      }
    },

    onCopyText(text) {
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length);
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！');
    },
  }
}
</script>

<style scoped lang="less">
.custom-loading {
  :deep(.van-pull-refresh__head) {
    .van-loading {
      .van-loading__spinner {
        color: #fff !important;
      }
      .van-loading__text {
        color: #fff !important;
      }
    }
    .van-pull-refresh__text {
      color: #fff !important;
    }
  }
}

.page-container {

}
.page-body{
  background-image: url('../../assets/img/invite/bg_invite.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.def-box {

  background-color:#FFF7EC;
  padding: 30px 15px;

  .left {
    width: 40%;
    text-align: center;
  }

  .right {
    display: flex;
    align-items: center;
    border-top: 1px dashed #AB0F13;;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn1 {
    display: block;
    background-image: linear-gradient(180deg, #FDE0B4 0%, #FFC771 100%);
    color: #AB0F13;
    border-radius: 4px;
    margin: 2rem auto .1rem;
    font-size: 14px;
    padding: 5px 10px;
  }

  .img {
    width: 60%;
    margin-top: 10px;
  }

  .text {
    color: #000;
    font-size: 16px;
  }
}

.share-address button {
  width: 45%;
  line-height: 35px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  background: rgba(255, 255, 255, .4);
  border-radius: 35px;
  display: block;
  margin: 0 auto;

  .btn1 {
    background-image: linear-gradient(180deg, #FDE0B4 0%, #FFC771 100%);
    width: 150px;
    margin-bottom: .1rem;
    color: #AB0F13;
  }
}




</style>
