<template>
  <div class="h-full w-full bg-[#F0F2F6]">
    <van-overlay :show="pageLoading" class="flex items-center justify-center z-50">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </van-overlay>

    <div class="overflow-y-auto w-full relative page-content-2">
      <!-- 顶部区域 -->
      <div class="relative">
        <img src="@/assets/img/bg_page_top.png" class="absolute top-0 left-0 w-full h-[180px] object-cover flex-shrink-0" />
        <div class="px-4 pt-8 relative z-10">
          <div class="text-white text-xl">平安保险</div>
          <div class="mt-4 text-white text-base opacity-80">
            中国平安基本养老保险是社会保险制度中最重要的险种之一。
          </div>
        </div>
      </div>

      <!-- 内容区域 -->
      <div class=" mt-4" style="margin-top: 8px;">
        <div class="flex-1 overflow-y-auto relative z-10 bg-[#F2F4F7] rounded-lg">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <!-- 基金优选标题 -->
            <div class="px-4 pt-4 pb-2 flex items-center ">
              <div class="text-base font-medium" style="font-size: 17px">养老保险</div>
              <div class="text-xs text-gray-400 ml-2">基本生活保障</div>
            </div>

            <div class="bg-white rounded-lg mx-4 mb-2">
              <div class="px-4 py-3">
                <div class="flex flex-col items-start">
                  <div class="relative inline-block">
                    <span style="color: #F23D30;
                               font-family: 'Abhaya Libre Medium';
                               font-size: 16px;
                               font-style: normal;
                               font-weight: 500;
                               line-height: normal;
                               letter-spacing: -0.5px;">基本保障</span>
                    <div class="absolute left-1/2 -translate-x-1/2 mt-1"
                         style="width: 20px; height: 3px; background: #F23D30;"></div>
                  </div>
                </div>
              </div>

              <div class="divide-y-0 divide-gray-100 mx-2 space-y-[10px] pb-4">
                <div v-for="(item, index) in list" :key="index" class="p-4 rounded-[8px]" style="background: #FFF2F2;">
                  <div class="flex">
                    <img :src="item.img" class="w-[74px] h-[74px] mr-2 flex-shrink-0 rounded-[6px]"
                         style="background: lightgray 0px 0px / 100% 100% no-repeat;" />
                    <div class="flex-1 min-w-0">
                      <div class="flex items-center overflow-hidden ">
                        <span class="text-base truncate">{{item.name}}</span>
                        <span class="ml-2 px-2 py-0.5 text-xs text-green-500 bg-green-50 rounded flex-shrink-0">低风险</span>
                      </div>

                      <div class="flex mt-4 text-center space-x-2">
                        <div class="flex-1 min-w-0">
                          <div class="text-[#F34947] font-medium truncate">{{ item.price }}</div>
                          <div class="text-xs text-gray-400 mt-1 break-words">价值</div>
                        </div>
                        <div class="flex-1 min-w-0">
                          <div class="text-[#F34947] font-medium truncate">{{ item.day_rate }}%</div>
                          <div class="text-xs text-gray-400 mt-1 break-words leading-tight">日利率</div>
                        </div>
                        <div class="flex-1 min-w-0">
                          <div class="text-[#F34947] font-medium truncate">{{ item.day_income }}<span class="text-[13px]">元</span></div>
                          <div class="text-xs text-gray-400 mt-1 break-words">每日分红</div>
                        </div>
                      </div>

                      <div class="flex items-center justify-between mt-4">
                          <span style="color: #FFA556;
                                     font-family: Alata;
                                     font-size: 13px;
                                     font-style: normal;
                                     font-weight: 400;
                                     line-height: normal;
                                     letter-spacing: -0.3px;">收益周期{{ item.total_day }}天</span>

                        <button class="flex-shrink-0 px-4 py-1 text-white text-sm rounded shadow-sm"
                                style="background: linear-gradient(90deg, #F23D30 -1.33%, #FF6A57 49.31%, #F23D30 96%);"
                                @click="handleImageClick(item)">购买</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </div>
      </div>
    </div>

    <!-- 支付弹窗 -->
    <payment-dialog
      v-model="showPayment"
      :amount="currentItem?.price"
      @confirm="handlePayConfirm"
    />
  </div>
</template>

<script>
import {Cell, CellGroup, Dialog, Icon, Loading, Overlay, Picker, Popup, PullRefresh, Radio, Toast} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from "vuex";
import PaymentDialog from '@/components/common/PaymentDialog.vue';

export default {
  name: "index",
  components: {
    [PullRefresh.name]: PullRefresh,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Dialog.Component.name]: Dialog.Component,
    [Radio.name]: Radio,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    PaymentDialog
  },
  data() {
    return {
      list: [],
      loading: false,
      refreshing: false,
      pageLoading: false,
      showPayment: false,
      currentItem: null,
      payType: 1
    }
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },

  created() {
    this.loadMore()
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),

    onRefresh() {
      this.loadMore()
    },

    async loadMore() {
      if (this.loading) return

      this.loading = true
      this.pageLoading = true

      try {
        const res = await axios.get(api.welfareList, {
          params: {
            type: 2
          }
        })

        this.list = res.data.idoList || []
      } catch (err) {
        Toast(err.msg || '加载失败')
      } finally {
        this.loading = false
        this.refreshing = false
        this.pageLoading = false
      }
    },

    async handleImageClick(item) {
      if (!this.userInfo?.is_identify) {
        await Dialog.alert({
          message: '请先完成实名认证',
          confirmButtonText: '去认证',
          confirmButtonColor: '#F34947',
        })
        this.$router.push({name: 'realNameAuth'})
        return
      }

      const params = {
        pay_type: 8,
        project_id: item.id,
        source: 2,
        amount: item.price
      }

      try {
        const loading = Toast.loading({
          message: '处理中...',
          forbidClick: true,
        })

        const res = await axios.post(api.balancePay, params)
        loading.clear()

        if (res.data.pay_url) {
          window.location.href = res.data.pay_url
        } else {
          await this.loadMore()
          await this.$dialog.alert({
            title: '提示',
            message: '购买成功！',
            theme: 'round-button',
            confirmButtonText: '确定',
            confirmButtonColor: '#F52735'
          })
        }
      } catch (err) {
        this.$toast(err.msg || '支付失败')
      }
    },

    async handlePayConfirm({payType}) {
      const params = {
        pay_type: 8,
        project_id: this.currentItem.id,
        source: 2,
        amount: this.currentItem.price
      }

      try {
        const loading = Toast.loading({
          message: '处理中...',
          forbidClick: true,
        })

        const res = await axios.post(api.balancePay, params)
        loading.clear()
        this.showPayment = false

        if (payType === 9) {
          await this.loadMore()
          await this.$dialog.alert({
            title: '提示',
            message: '购买成功！',
            theme: 'round-button',
            confirmButtonText: '确定',
            confirmButtonColor: '#F52735'
          })
        } else {
          window.location.href = res.data.pay_url
        }
      } catch (err) {
        this.$toast(err.msg || '支付失败')
      }
    }
  }
};
</script>

<style scoped lang="less">
.top-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.custom-loading {
  :deep(.van-pull-refresh__head) {
    .van-loading {
      .van-loading__spinner {
        color: #fff !important;
      }
      .van-loading__text {
        color: #fff !important;
      }
    }
    .van-pull-refresh__text {
      color: #fff !important;
    }
  }
}

.map-container {
  width: 100%;
  height: 200px; // 减小高度
  border-radius: 10px;
}
</style>
