<template>
  <div class="foot_menu w-full">
    <Tabbar v-model="active" active-color="#FE4B4C" inactive-color="#999999" :border="false" @change="changeTab">
      <TabbarItem
          v-for="(item, index) in filteredMenuList"
          :key="index"
      >
        <span>{{ item.title }}</span>
        <template #icon="props">
          <img :src="props.active ? item.sel_pic : item.pic"/>
        </template>
      </TabbarItem>
    </Tabbar>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import UserStorage from "../utils/user_storage";
import {Icon, Tabbar, TabbarItem} from "vant";

export default {
  props: ['pageKey'],
  components: {
    Icon,
    Tabbar,
    TabbarItem,
  },
  computed: {
    filteredMenuList() {
      return this.menuList.filter(item => {
        if (item.title === '保险基金') {
          return this.bankCardInfo?.status === 2;
        }
        return item.isShow === 1;
      });
    }
  },
  data() {
    return {
      active: "首页",
      bankCardInfo: null,
      menuList: [
        {
          "title": "首页",
          "isShow": 1,
          "url": "/home",
          "pic": require('@/assets/img/nav/menu_home.png'),
          "sel_pic": require('@/assets/img/nav/menu_home_checked.png'),
        },
        {
          "title": "保险基金",
          "isShow": 1,
          "url": "/insuranceFund",
          "pic": require('@/assets/img/nav/menu_zhzj.png'),
          "sel_pic": require('@/assets/img/nav/menu_zhzj_checked.png'),
        },
        {
          "title": "资产结算",
          "isShow": 1,
          "url": "/capitalAssets",
          "pic": require('@/assets/img/nav/menu_zhzx.png'),
          "sel_pic": require('@/assets/img/nav/menu_zhzx_checked.png'),
        },
        {
          "title": "平安保险",
          "isShow": 1,
          "url": "/insurance",
          "pic": require('@/assets/img/nav/menu_zhzx.png'),
          "sel_pic": require('@/assets/img/nav/menu_zhzx_checked.png'),
        },

        // {
        //   "title": "在线客服",
        //   "isShow": 1,
        //   "url": this.$config.KF_URL,
        //   "pic": require('@/assets/img/nav/menu_kf.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_kf_checked.png'),
        // },
        {
          "title": "我的",
          "isShow": 1,
          "url": "/profile",
          "pic": require('@/assets/img/nav/menu_my.png'),
          "sel_pic": require('@/assets/img/nav/menu_my_checked.png'),
        }],
    };
  },
  created() {
    this.$bus.$on('active', this.handleMessage);
  },
  beforeDestroy() {
    this.$bus.$off('active', this.handleMessage); // 在组件销毁前移除监听器
  },
  mounted() {
    console.log('加载页面')
    this.init();
    this.getBankCardInfo();
  },
  methods: {
    handleMessage(index) {
      console.log('收到事件', index)
      this.active = index;
    },
    matchRoute(menuPath, routePath) {
      // 处理首页特殊情况
      if (menuPath === '/home' && routePath === '/') return true;
      return routePath.startsWith(menuPath);
    },
    init() {
      const currentPath = this.$route.path;
      const activeIndex = this.filteredMenuList.findIndex(item =>
        this.matchRoute(item.url, currentPath)
      );
      if (activeIndex !== -1) {
        this.active = activeIndex;
      }
    },
    changeTab(index) {
      this.active = index;
      const item = this.filteredMenuList[index];
      if (item.title == '在线客服') {
        window.location.href = item.url;
        return;
      }
      this.navigateTo(item.url);
    },
    navigateTo(url) {
      this.$router.push(url);
    },
    //获取导航菜单
    getNavigation() {
      axios
          .get(api.navList)
          .then((res) => {
            const data = res.data;
            let list = [];
            data.map((item) => {
              if (item.isShow) {
                list.push(item);
              }
            });
            console.log("菜单列表", list);
            this.menuList = list;
            UserStorage.setNavList(list);
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
    getBankCardInfo() {
      axios.get(api.myBankOrder, { params: { type: 1 } })
        .then(res => {
          this.bankCardInfo = res.data;
        })
        .catch(err => {
          console.log('获取银行卡信息失败', err);
        });
    },
  },
  watch: {
    $route: {
      handler(to) {
        const activeIndex = this.filteredMenuList.findIndex(item => item.url === to.path);
        if (activeIndex !== -1) {
          this.active = activeIndex;
        }
      },
      immediate: true
    }
  },
};
</script>

<style lang="less" scoped>
.foot_menu {
  height: 50px;
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.van-tabbar-item__icon img {
  height: 1.5rem;
}
</style>
