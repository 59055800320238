// 该文件专门用于创建整个应用的路由器
import VueRouter from "vue-router";
import Login from "@/pages/login";
import Register from "../pages/register";
import Home from "../pages/home";
import UserStorage from "../utils/user_storage";
import NewsDetails from "@/pages/news/NewsDetails";
import InviteUser from "@/pages/invite/user";
import Main from "@/pages/main";
import AssetsRecord from "@/pages/assets/record";
import BuyAssets from "@/pages/assets/buy";
import Dividend from "@/pages/dividend";
import BuyBonusList from "@/pages/dividend/record";
import BuyDividend from "@/pages/dividend/buy";
import Profile from "@/pages/profile";
import UserInfo from "@/pages/profile/details";
import RealNameAuth from "@/pages/profile/realNameAuth";
import BindBankCard from "@/pages/profile/bindBankCard";
import Withdraw from "@/pages/profile/withdraw";
import WithdrawRecord from "@/pages/profile/withdrawRecord";
import FundDetails from "@/pages/profile/fundDetails";
import ContactUs from "@/pages/settings/contactUs";
import Team from "@/pages/team";
import ChildTeam from "@/pages/team/child";
import CustomService from "@/pages/settings/customService";
import AwardRecord from "@/pages/award/record";
import MyHouse from "@/pages/profile/myHouse";
import MyCards from "@/pages/profile/myCards";
import AddressList from "@/pages/address/addressList";
import EditAddress from "@/pages/address/editAddress";
import RedHeaderFile from "@/pages/settings/redHeaderFile";
import ModifyPwd from "@/pages/settings/modifyPwd";
import SignIn from "@/pages/profile/signin";
import Assure from "@/pages/dividend/assure";
import InsuranceFund from "@/pages/invite/insuranceFund";
import BankCardList from "@/pages/dividend/bankCardList";
import AddAddress from "@/pages/dividend/addAddress";
import BuyCard from "@/pages/dividend/buyCard";
import Insurance from "@/pages/invite/insurance";
import ActivateProvince from "@/pages/invite/activateProvince.vue";
import EnvWallet from "@/pages/profile/envWallet.vue";
import TeamInvite from "@/pages/invite/teamInvite.vue";
import UserInvite from "@/pages/invite/userInvite.vue";
import CapitalAssets from "@/pages/invite/capitalAssets.vue";
import MyAddress from "@/pages/address/myAddress.vue";
import ActivateBankCard from "@/pages/home/activateBankCard.vue";
import SettlementRecords from "@/pages/invite/settlementRecords.vue";
import BusinessLicense from "@/pages/settings/businessLicense.vue";

//创建并暴露一个路由器
const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            component: Main,
            name: "main",
            meta: {title: "主页"},
            children: [
                {
                    path: "/home",
                    component: Home,
                    name: "home",
                    meta: {title: "首页"},
                },
                {
                    path: '/insuranceFund',
                    component: InsuranceFund,
                    name: 'insuranceFund',
                    meta: {title: '保险基金'},
                },
                {
                    path: '/insurance',
                    component: Insurance,
                    name: 'insurance',
                    meta: {title: '平安保险'},
                }, {
                    path: '/capitalAssets',
                    component: CapitalAssets,
                    name: 'capitalAssets',
                    meta: {title: '百姓扶贫银行卡'},
                },
                {
                    path: "/dividend",
                    component: Dividend,
                    name: "dividend",
                    meta: {title: "百姓国债"},
                },{
                    path: "/assure",
                    component: Assure,
                    name: "assure",
                    meta: {title: "百姓保障"},
                },
                {
                    path: "/insurance",
                    component: Insurance,
                    name: "insurance",
                    meta: {title: "百姓保险"},
                },
                {
                    path: "/profile",
                    component: Profile,
                    name: "profile",
                    meta: {title: "我的"},
                },
                {
                    path: '/',
                    redirect: '/home',
                },
            ]
        },
        {
            path: "/login",
            component: Login,
            name: "login",
            meta: {title: "登录"},
        },
        {
            path: "/register",
            component: Register,
            name: "register",
            meta: {title: "注册"},
        },

        {
            path: '/news/details/:id',
            component: NewsDetails,
            name: 'newsDetails',
            meta: {title: '新闻资讯'},
        },
        {
            path: '/contactus',
            component: ContactUs,
            name: 'contactUs',
            meta: {title: '官方群聊'},
        }, {
            path: '/customService',
            component: CustomService,
            name: 'customService',
            meta: {title: '在线客服'},
        },
        {
            path: '/invite',
            component: InviteUser,
            name: 'inviteUser',
            meta: {title: '邀请好友'},
        },
        {
            path: '/team',
            component: Team,
            name: 'team',
            meta: {title: '我的团队'},
        },
        {
            path: '/team/child/:level',
            component: ChildTeam,
            name: 'childTeam',
            meta: {title: '我的团队'},
        },
        {
            path: "/assets/buyAssets",
            component: BuyAssets,
            name: "buyAssets",
            meta: {title: "立即恢复"},
        },
        {
            path: "/dividend/buy",
            component: BuyDividend,
            name: "buyDividend",
            meta: {title: "立即购买"},
        },
        {
            path: "/dividend/record",
            component: BuyBonusList,
            name: "buyBonusList",
            meta: {title: "购买记录"},
        },
        {
            path: "/profile/details",
            component: UserInfo,
            name: "userInfo",
            meta: {title: "个人资料"},
        }, {
            path: "/profile/realNameAuth",
            component: RealNameAuth,
            name: "realNameAuth",
            meta: {title: "实名认证"},
        }, {
            path: "/profile/bindBankCard",
            component: BindBankCard,
            name: "bindBankCard",
            meta: {title: "绑定银行卡"},
        },
        {
            path: "/profile/withdraw",
            component: Withdraw,
            name: "withdrawal",
            meta: {title: "提现"},
        },
        {
            path: "/assets/record",
            component: AssetsRecord,
            name: "assetsRecord",
            meta: {title: "资产恢复记录"},
        },
        {
            path: "/withdrawRecord",
            component: WithdrawRecord,
            name: "withdrawRecord",
            meta: {title: "提现记录"},
        },
        {
            path: "/award/record",
            component: AwardRecord,
            name: "awardRecord",
            meta: {title: "抽奖记录"},
        },
        {
            path: "/myHouse",
            component: MyHouse,
            name: "myHouse",
            meta: {title: "我的房产"},
        },{
            path: "/myCards",
            component: MyCards,
            name: "myCards",
            meta: {title: "我的卡包"},
        },
        // {
        //     path: "/address",
        //     component: AddressList,
        //     name: "address",
        //     meta: {title: "收货地址"},
        // },
        {
            path: "/address",
            component: MyAddress,
            name: "address",
            meta: {title: "我的地址"},
        },
        {
            path: "/redHeaderFile",
            component: RedHeaderFile,
            name: "redHeaderFile",
            meta: {title: "红头文件"},
        },
        {
            path: "/modifyPwd",
            component: ModifyPwd,
            name: "modifyPwd",
            meta: {title: "修改密码"},
        },
        {
            path: "/buyCard",
            component: BuyCard,
            name: "buyCard",
            meta: {title: "立即支付"},
        },
        {
            path: "/signIn",
            component: SignIn,
            name: "signIn",
            meta: {title: "签到"},
        },
        {
            path: "/addAddress",
            component: AddAddress,
            name: "addAddress",
            meta: {title: "完善收货地址"},
        },
        {
            path: "/activateProvince",
            component: ActivateProvince,
            name: "activateProvince",
            meta: {title: "激活省份"},
        },
        {
            path: "/envWallet",
            component: EnvWallet,
            name: "envWallet",
            meta: {title: "资环钱包"},
        },
        {
            path: "/teamInvite",
            component: TeamInvite,
            name: "teamInvite",
            meta: {title: "团队奖励"},
        },
        {
            path: '/userInvite',
            component: UserInvite,
            name: 'userInvite',
            meta: {title: '邀请好友'},
        },
        {
            path: "/fundDetail",
            component: FundDetails,
            name: "fundDetail",
            meta: {title: "资金明细"},
        },
        {
            path: "/activateBankCard",
            component: ActivateBankCard,
            name: "activateBankCard",
            meta: {title: "黑金卡"},
        },
        {
            path: '/settlement-records',
            name: 'settlementRecords',
            component: SettlementRecords,
            eta: {title: "申请记录"},
        }, {
            path: '/businessLicense',
            name: 'businessLicense',
            component: BusinessLicense,
            eta: {title: "营业执照"},
        },
    ],
});

/**
 * 解决报错问题：报错显示是路由重复
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    // 判断目标路由是否与当前路由相同
    if (location.path !== this.currentRoute.path) {
        return originalPush.call(this, location).catch(err => err);
    }
}
//全局后置路由守卫————初始化的时候被调用、每次路由切换之后被调用
router.afterEach((to, from) => {
    //切换路由，页面直接滚动到顶部
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
});

// 导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === "/login" ||to.path === "/invite" || to.path === "/register" || to.path === "/password/editPassword") {
        // 如果目标页面是登录页，直接放行
        return next()
    }
    const isAuthenticated = UserStorage.getToken(); // 判断用户是否已登录的函数
    // 如果目标页面不是登录页
    if (!isAuthenticated && to.path !== '/login') {
        return next('/login')
    }
    next();
});

export default router;
