<template>
  <div class="h-full w-full bg-[#F0F2F6]">
    <div class="overflow-y-auto w-full relative page-content-2">
      <div class=" relative">
        <img src="@/assets/img/home/bg_top.png"
             class="absolute top-0 left-0 w-full h-[270px] object-cover flex-shrink-0"/>
        <div class="px-4 pt-8 relative z-10">
          <div class="text-white text-xl">首页</div>

          <div class="mt-4">
            <!-- Bank card image -->
            <bank-card-generator
                v-if="bankCardInfo"
                :card-data="bankCardInfo"
            />
            <img
                v-else
                src="@/assets/img/bank-logo.png"
                class="w-full"
                @click="jumpToPage('activateBankCard')"
            />

            <!-- Credit limit and withdrawal section -->
            <div class="mt-4 bg-[#F3F9FF] rounded p-4">
              <div class="flex items-center justify-between">
                <div>
                  <div class="text-sm opacity-80 text-black flex items-center">
                    <span>额度</span>
                    <img src="@/assets/img/profile/eye_open.png" class="w-[13px] h-[9.517px] flex-shrink-0 ml-1"/>
                  </div>
                  <div class="text-2xl font-medium mt-1 text-black">
                    <span class="text-sm">¥</span>{{ walletInfo?.withdraw_wallet?.num || '0.00' }}
                  </div>
                </div>
                <div class="withdrawal-btn px-6 py-2 rounded text-white" @click="handleWithdrawalClick">
                  {{ bankCardInfo?.status === 2 ? '提现' : '申领' }}
                </div>
              </div>
            </div>

            <!-- Menu below credit limit and withdrawal -->
            <HomeMenu class="mt-4"/>
          </div>


        </div>
      </div>

      <div class="px-4 mt-4 bg-white rounded shadow py-4 mx-4">
        <div class="text-lg font-medium mb-2 text-[#F23D30] relative inline-block">
          平安视窗
          <div
              class="absolute bottom-[-4px] left-1/2 transform -translate-x-1/2 w-[20px] h-[3px] bg-[#F23D30] flex-shrink-0"></div>
        </div>
        <HomeVideo/>
      </div>

      <div class="px-4 mt-4">
        <div class="flex items-center mb-2">
          <div class="text-lg font-medium">平安资讯</div>
        </div>
        <News/>
      </div>
    </div>
    <popup v-for="(notice, index) in notices"
           :key="index"
           v-model="notice.show"
           position="center"
           :closeable="false">
      <div class="bg-white rounded-[20px] overflow-hidden">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center py-4">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white px-4 py-3 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="notice.content" class="text-[16px]"></p>
        </div>
        <div class="py-6 text-[16px] flex justify-center">
          <div @click="closeDialog(index)" class="bg-primary text-white px-12 py-2.5 rounded-full cursor-pointer hover:opacity-90">
            我知道了
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import HomeMenu from "@/components/home/HomeMenu";
import News from "@/components/home/News";
import {mapActions, mapState} from "vuex";
import HomeVideo from "@/components/home/HomeVideo";
import {Popup,} from "vant";
import api from "@/net/api";
import axios from "@/net/axios";
import BankCardGenerator from "@/pages/home/bankCardGenerator.vue";

export default {
  name: "index",
  components: {BankCardGenerator, HomeVideo, HomeMenu, News, Popup},
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title;
    }
  },
  data() {
    return {
      notices: [],
      currentNoticeIndex: 0,
      bankCardInfo: null,
      walletInfo: null
    };
  },
  created() {
    this.getNoticeList();
  },
  mounted() {
    console.log("首页mounted");
    this.getUserInfo();
    this.getSystemInfo();
    this.getBankCardInfo();
    this.getUserWallet();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),

    jumpToPage(name) {
      this.$router.push({name});
    },
    closeDialog(index) {
      this.notices[index].show = false;
      this.currentNoticeIndex++;
      if (this.currentNoticeIndex < this.notices.length) {
        setTimeout(() => {
          this.showNextNotice();
        }, 300);
      }
    },
    getNoticeList() {
      axios
          .get(api.newsList, {params: {type: 1, index_status: 1}})
          .then((res) => {
            if (res.data.length > 0) {
              this.notices = res.data.map(notice => ({
                ...notice,
                show: false
              }));
              this.showNextNotice();
            }
          })
          .catch(() => {
            console.log('获取公告失败');
          });
    },

    showNextNotice() {
      if (this.currentNoticeIndex < this.notices.length) {
        this.notices[this.currentNoticeIndex].show = true;
      }
    },

    getBankCardInfo() {
        axios.get(api.myBankOrder, { params: { type: 1 } })
            .then(res => {
                this.bankCardInfo = res.data;
            })
            .catch(err => {
                console.log('获取银行卡信息失败', err);
            });
    },

    getUserWallet() {
      axios.get(api.userWallet)
        .then((res) => {
          if (res.code === 200 && res.data) {
            const walletInfo = {};
            res.data.coinList.forEach(coin => {
              walletInfo[coin.name_en] = coin;
            });
            this.walletInfo = walletInfo;
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleWithdrawalClick() {
      if (this.bankCardInfo?.status !== 2) {
        if (this.userInfo.is_identify === 0) {
          this.$dialog.alert({
            message: '请先完成实名注册',
            confirmButtonText: '去实名',
          }).then(() => {
            this.jumpToPage('realNameAuth');
          });
        } else if (this.userInfo.recommend_identify_num <= 0) {
          this.$dialog.alert({
            message: '邀请一人实名注册即可申请',
            confirmButtonText: '去邀请',
          }).then(() => {
            this.jumpToPage('userInvite');
          });
        } else {
          this.jumpToPage('activateBankCard');
        }
      } else {
        this.$router.push({
          name: "withdrawal",
          query: this.walletInfo.withdraw_wallet,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.bg-primary {
  background: linear-gradient(180deg, #FF4B4B 0%, #FF7676 100%);
}

.bank-card {
  background: linear-gradient(135deg, #1A1A1A 0%, #363636 100%);
}

.bottom {
  background-image: linear-gradient(113deg, #4B49FF 0%, #7D9AFF 100%);
  border-radius: 4px;
}

.notice-title {
  width: 320px;
  height: 67px;
}

.item {
  box-shadow: 0px 0px 11.6px 0px rgba(193, 193, 193, 0.25);
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  color: #333;
  font-size: 16px;
}

.content-wrapper {
  position: relative;
  z-index: 1;
}

.banner-img {
  margin-bottom: -40px;
}

:deep(.van-popup) {
  border-radius: 20px;
  overflow: hidden;
}

.notice-title {
  padding: 16px 0;
  border-bottom: 1px solid #f5f5f5;
}

.withdrawal-btn {
  background: linear-gradient(90deg, #F23D30 0%, #FF6A57 48.44%, #F23D30 100%);
}
</style>
